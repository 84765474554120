import styles from './hero.module.css';

const Hero = () => {
    return (
        <div className="hero">
            <div className={styles.heroContainer}>
                <h1 className={styles.heroTitle}>Your Premier Online Notary Public</h1>
                <h2 className={styles.heroSubtitle}>Where Professionalism Meet Convenience!</h2>
                <img className={styles.heroLogo} src="./icons/notary_logo.png" alt="Notary Public Logo" />
                <button className={styles.heroButton}>Book Now</button>
            </div>
        </div>
    )
}

export default Hero;