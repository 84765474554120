import styles from './whyUsSection.module.css';

const WhyUsSection = () => {
    return (
        <div className={styles.container}>
            <h1>Why Choose Florida Online Notary Services?</h1>
            <div className={styles.bubblesContainer}>
                <div className={styles.bubble}>
                    <img src="./icons/Convenience.png" alt="Convenience" className={styles.bubbleIcon} />
                    <h2 className={styles.bubbleTitle}>Convenience</h2>
                    <p className={styles.bubbleDescription}>Our online notary services are available 24/7. You can get your documents notarized from the comfort of your home or office.</p>
                </div>
                <div className={styles.bubble}>
                    <img src="./icons/security_icon.png" alt="Security icon" className={styles.bubbleIcon} />
                    <h2>Security</h2>
                    <p>Our online notaries are certified by the state of Florida and have passed a rigorous background check.</p>
                </div>
                <div className={styles.bubble}>
                    <img src="./icons/speed_icon.png" alt="Speed icon" className={styles.bubbleIcon} />
                    <h2>Speed</h2>
                    <p>Our online notaries will notarize your documents in minutes. No need to wait for an appointment.</p>
                </div>
                <div className={styles.bubble}>
                    <img src="./icons/savings_icon.png" alt="Savings icon" className={styles.bubbleIcon} />
                    <h2>Price</h2>
                    <p>Our online notary services are only $25 per notarization. No hidden fees.</p>
                </div>
            </div>
        </div>
    )
}

export default WhyUsSection;