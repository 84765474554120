import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import WhyUsSection from './components/WhyUsSection/WhyUsSection';

function App() {
  return (
    <div className="App">
      <Header />
      <div>
        <Hero />
        <WhyUsSection />
      </div>
      <Footer />
    </div>
  );
}

export default App;
