import styles from './footer.module.css';

const Footer = () => {
    return (
        <div className={styles.container}>
            <p>© 2021 - All rights reserved</p>
        </div>
    )
}

export default Footer;